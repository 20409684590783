
import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";

type Task = {
  id: number;
};

@Component({
  filters: {
    date_from(value) {
      if (!value) return;

      return moment(value).format("Do MMMM");
    }
  }
})
export default class TaskComponent extends Vue {
  @Prop(Object) readonly task!: Task;
}
